@import '../../../style/variables';

.pveFaq {
    &Question {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 10px;
        color: $mainColor;
        text-align: left;
    }
    &First {
        margin-top: 50px;
    }
    &Answer {
        font-family: $secondaryFont;
        font-size: 20px;
        margin-bottom: 10px;
        color: $thirdColor;
        text-align: justify;
        font-weight: 400;
    }
    &Color {
        &Common {
            color: $rarityColorCommon;
        }
        &Uncommon {
            color: $rarityColorUncommon;
        }
        &Rare {
            color: $rarityColorRare;
        }
        &Epic {
            color: $rarityColorEpic;
        }
        &Legendary {
            color: $rarityColorLegendary;
        }
    }
    &Img {
        display: block;
        margin: 0 auto;
        margin-top: 25px;
    }
    &LastAnswer {
        margin-bottom: 200px;
    }
}

@media ( max-width: 550px ) {
    .pveFaq {
        &Img {
            width: 300px;
        }  
    }
}

@media ( max-width: 350px ) {
    .pveFaq {
        &Img {
            width: 280px;
        }  
    }
}