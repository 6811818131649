$bgColor: #181818;
$bgHeader: #000;

$mainColor: #67b402;
$secondaryColor: #b42427;
$thirdColor: #fff;

$strengthColor: rgb(129, 194, 89);
$staminaColor: rgb(241, 86, 76);
$speedColor: rgb(130, 109, 212);

$rarityColorCommon: rgb(178, 183, 190);
$rarityColorUncommon: rgb(129, 194, 89);
$rarityColorRare: rgb(71, 172, 243);
$rarityColorEpic: rgb(130, 109, 212);
$rarityColorLegendary: rgb(255, 184, 47);

$winColor: #fdbb53;

$warningTextColor: #ff681d;

$linkColor: #fdbb53;
$linkColorHover: #b18239;

$skeletonBg: #424242;

$inputDisabled: #949494;
$inputBorderDisabled: rgba(151, 147, 147, 0.96);

$progressBarBg: #eceff4;
$progressBarBorder: #fff;

$lotteryProgressShadow: rgba(64 235 50 / 20%);

$faucetGradientFirstColor: #00ff53;
$faucetGradientSecondColor: #096407;

$faucetCounterGradientFirstColor: #45484d;
$faucetCounterGradientSecondColor: #000000;
$faucetCounterShadowColor: #170101;

$primaryFont: "Shlop";
$secondaryFont: "Krub";