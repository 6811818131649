@import '../../style/variables';

.main {
    &Title {
        font-family: $primaryFont;
        color: $mainColor;
        font-size: 100px;
        display: flex;
        align-items: center;
        &Wrapper {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
        &Img {
            width: 100px;
            height: 100px;
        }
    }
    &Tile {
        background-color: rgba(0, 0, 0, .3) !important;
        backdrop-filter: blur(20px);
        transition: box-shadow .3s cubic-bezier(.4,0,.2,1) 0ms;
        border: 12px solid $mainColor;
        border-radius: 0;
        width: 300px;
        height: 150px;
        border-image-source: url('../../assets/frames/frame-slime-2.png') !important;
        border-image-slice: 98 !important;
        border-image-repeat: stretch !important;
        border-image-width: auto !important;
        border-image-outset: 22px 9px 51px 6px;
        &Wrapper {
            display: flex;
            justify-content: space-around;
            margin-top: 50px;
        }
        &Title {
            font-family: $primaryFont;
            color: $secondaryColor;
            font-size: 35px;
            margin-top: 27px;
        }
        &Value {
            font-family: $primaryFont;
            color: $secondaryColor;
            font-size: 35px;
            margin-top: 10px;
        }
    }
}