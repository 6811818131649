@import './../../style/variables';

.pveWithdraw {
    &Wrapper {
        margin-bottom: 100px;
    }
    // &Line {
    //     border: 1px solid #7c7b7b;
    //     height: 2px;
    //     width: 100%;
    //     margin: 0 auto;
    //     margin-top: 45px;
    // }
    &List {
        &Item {
            color: $warningTextColor;
            font-family: $secondaryFont;
            font-weight: 400;
            font-size: 17px;
            margin-top: 3px;
            span {
                color: $thirdColor;
                text-decoration: underline;
            }
        }
        &Wrapper {
            margin-top: 20px;
            margin-bottom: 40px;
            padding: 20px 45px 20px 20px;
            border-image-source: url('../../assets/frames/warning-slime-frame.png') !important;
            border-image-slice: 235 !important;
            border-image-repeat: stretch !important;
            border-image-width: auto !important;
            border-image-outset: 13px 10px 26px 9px;
        }
    }
    &Address {
        margin-top: 10px;
    }
    &Balance {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 17px;
        margin-top: 5px;
        span {
            color: $winColor;
        }
        &TokenSymbol {
            color: $secondaryColor !important; 
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 18px;
        }
    }
    &FormWrapper {
        margin-top: 10px;
        width: 400px;
    }
    &MaxBtn {
        font-family: $primaryFont !important;
        font-size: 20px !important;
        width: 50px;
        letter-spacing: 1px !important;
    }
    &Subtitle {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 25px;
        margin-top: 15px;
    }
    &Receive {
        color: $winColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 25px;
        margin-top: 15px;
    }
    &NoFee {
        color: $mainColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 25px;
        margin-top: 15px;
    }
    &LinkSolscan {
        color: $winColor;
        font-size: 20px;
    }
    &ReceiveTokenSymbol {
        color: $secondaryColor !important; 
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 26px;
    }
}

// value input withdraw
.css-mnn31.Mui-disabled {
    -webkit-text-fill-color: $inputDisabled !important;
}