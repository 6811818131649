@import '../../style/variables';

.pve {
    &Form {
        &Title {
            color: $secondaryColor;
            font-family: $primaryFont;
            font-size: 80px;
            margin-top: 100px;
        }
        &Bg {
            width: 400px;
            height: 370px;
            margin: 0 auto;
            padding: 20px;
            background-color: rgba(0, 0, 0, 0.3) !important;
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            margin-top: 75px;
            margin-bottom: 200px;
            border-image-source: url('../../assets/frames/frame-login.png') !important;
            border-image-slice: 94 !important;
            border-image-repeat: stretch !important;
            border-image-width: auto !important;
            border-image-outset: 45px 23px 55px 26px;
            &Register {
                height: 450px;
            }
        }
        &LoginRegisterTitle {
            display: inline-block;
            letter-spacing: 1px;
            color: $linkColor;
            cursor: pointer;
            &:hover {
                color: $linkColorHover !important;
            }
        }
        &LoginRegisterBetweenTitle {
            display: inline-block;
            margin: 0px 15px 0px 15px;
        }
        &InputWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
        }
        &Email {
            width: 300px;
            label {
                color: $secondaryColor !important;
            }
        }
        &Password {
            width: 300px;
            margin-top: 20px !important;
            label {
                color: $secondaryColor !important;
            }
        }
        &BtnWrapper {
            margin: 0 auto;
            margin-top: 20px;
            width: 250px;
            display: flex;
            justify-content: space-around;
        }
        &LoginImg {
            position: absolute;
            top: 314px;
            left: 110px;
            width: 52px;
            height: 62px;
            transform: rotate(-10deg);
        }
        &RegisterImg {
            position: absolute;
            top: 390px;
            left: 105px;
            width: 52px;
            height: 62px;
            transform: rotate(-10deg);
        }
        &Btn {
            font-family: $primaryFont !important;
            font-size: 20px !important;
            letter-spacing: 1px !important;
        }
    }
    &ErrorLoginMsg {
        color: $secondaryColor;
        font-family: $primaryFont;
        font-size: 45px;
        margin-top: 25px;
    }
    &Captcha {
        width: 300px;
        margin: 0 auto;
        margin-top: 20px;
    }
    &FormSpinner {
        margin-top: 80px;
        width: 250px !important;
        height: 250px !important;
    }
}

#email, #password, #refCode {
    color: $secondaryColor !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: $mainColor !important;
}

svg[data-testid="VisibilityIcon"], svg[data-testid="VisibilityOffIcon"] {
    fill: $mainColor !important;
}