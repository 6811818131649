@import './../../style/variables';

.pveLottery {
    &Wrapper {
        margin-bottom: 100px;
    }
    &Description {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 17px;
        margin-top: 10px;
        &Wrapper {
            margin-top: 20px;
            padding: 20px;
            border-image-source: url('../../assets/frames/green-slime-frame-2.png') !important;
            border-image-slice: 365 !important;
            border-image-repeat: stretch !important;
            border-image-width: auto !important;
            border-image-outset: 15px 14px 16px 12px;
        }
    }
    &Title {
        color: $mainColor;
        font-family: $primaryFont;
        font-size: 45px;
        margin-top: 35px;
    }
    &Update {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-size: 13px;
        font-weight: 400;
        text-align: left;
        margin-top: 10px;
    }
    &RulesLink {
        color: $linkColor;
        &:hover {
            color: $linkColorHover;
        }
    }
    &Current {
        &Wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-top: 10px;
        }
        &Title {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 15px;
        }
        &Content {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 400;
            font-size: 15px;
            margin-top: 5px;
        }
        &Prize {
            color: $winColor;
            font-size: 17px;
            &PoolTokenSymbol {
                color: $secondaryColor; 
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 18px;
            }
        }
    }
    &Place {
        color: #fff;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 10px;
        span {
            color: $winColor;
        }
        &TokenSymbol {
            color: $secondaryColor !important; 
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 23px;
        }
        &Wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 300px;
            margin: 0 auto;
            margin-top: 20px;
            align-items: center;
        }
        &PastTitleWrapper {
            display: flex;
            justify-content: space-evenly;
            margin-top: 25px;
            &Item {
                margin-top: 5px;
            }
        }
        &TitleItem {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 15px;
            width: 150px;
        }
        &Item {
            width: 150px;
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 400;
            font-size: 15px;
            margin-top: 5px;
            &Wlkn {
                color: $winColor;
                font-size: 17px;
            }
        }
    }
    
    &MyTickets {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 30px;
        span {
            color: $mainColor;
        }
        &Wrapper {
            display: flex;
            justify-content: space-around;
            margin-top: 40px;
        }
    }
    &NextTicket {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        padding-top: 7px;
    }
    &ProgressBarLine {
        background: $thirdColor;
        height: 6px;
        width: 200px;
        margin-top: 11px;
    }
    &ProgressBarOuter {
        box-shadow: 0px 0px 9px 5px $lotteryProgressShadow;
        height: 6px;
        width: 0px;
        background: $mainColor;
    }
    &UserTurnover {
        margin-left: 10px;
        margin-right: 10px;
        color: $winColor;
    }
    &UserBeforeTicket {
        margin-left: 10px;
        color: $winColor;
    }
    &TitlePast {
        margin-top: 80px;
        margin-bottom: 20px;
    }

    &LinksWrapper {
        display: flex;
        justify-content: space-evenly;
        margin-top: 40px;
    }
    &TitleLink {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 22px;
    
    }

    // history
    &HistoryItem {
        margin-bottom: 45px;
    }
}