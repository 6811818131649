.pveLotteryCurrentItem {
    &Break900, &Break600, &Break400 {
        display: none;
    }
}

@media ( max-width: 950px ) {
    .pveLotteryCurrentItem {
        margin-bottom: 20px;
        &Break900 {
            display: flex;
            width: 100%;
        }
    }
}

@media ( max-width: 600px ) {
    .pveLotteryCurrentItem {
        &Break900 {
            display: none;
        }
        &Break600 {
            display: flex;
            width: 100%;
        }
    }
}

@media ( max-width: 450px ) {
    .pveLotteryCurrentItem {
        &Break900, &Break600 {
            display: none;
        }
        &Break400 {
            display: flex;
            width: 100%;
        }
    }
}