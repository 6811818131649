@import './../../style/variables';

.header {
    &Wrapper {
        padding: 0 20px 0 20px;
        display: flex;
        justify-content: space-between;
        background-color: $bgHeader;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
    }
    &LogoWrapper {
        display: flex;
        width: 350px;
    }
    &LogoImg {
        width: 60px;
        height: 60px;
        border-radius: 20px;
    }
    &Logo {
        text-decoration: none;
    }
    &LogoText {
        font-family: $primaryFont;
        color: $mainColor;
        font-size: 50px;
        display: flex;
        align-items: center;
    }
    &Message {
        color: $warningTextColor;
        font-family: $primaryFont;
        font-size: 18px;
        margin-left: 5px;
        margin-top: 3px;
    }
    &Link {
        text-decoration: none;
        color: $mainColor;
        display: block;
        font-family: $primaryFont;
        font-size: 30px;
        display: flex;
        align-items: center;
        &Wrapper {
            width: 750px;
            display: flex;
            justify-content: space-around;
        }
    }
    &Account {
        width: 350px;
        padding-right: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        &Img {
            width: 55px;
            height: 55px;
        }
        &Name {
            font-family: $primaryFont;
            color: $secondaryColor;
            font-size: 19px;
        }
        &Btn {
            height: 40px;
            font-family: $primaryFont !important;
            font-size: 20px !important;
        }
    }
    &BloodImg {
        position: absolute;
        right: 40px;
        top: 60px;
        width: 225px;
    }
}

@media ( max-width: 900px ) {
    .header {
        &LogoImg {
            width: 65px;
            height: 65px;
        }
        &TextLogo {
            font-size: 21px !important;
            width: 120px;
            margin-left: -15px !important;
        }
    }
}