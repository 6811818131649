@import './../../style/variables';

.dashboard {
    &Error {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        font-family: $primaryFont;
        color: $secondaryColor;
    }
    &Token {
        color: $mainColor;
        font-family: $primaryFont;
        font-size: 35px;
        margin-top: 5px;
        span {
            color: $secondaryColor;
        }
    }
    &SubTitle {
        font-size: 45px;
        margin-top: 30px;
    }
    &GameParams {
        &Wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
            font-weight: 600;
            &Line2 {
                justify-content: space-around;
            }
            &Line3 {
                justify-content: space-between;
                margin-bottom: 100px;
            }
        }
        &Item {
            width: 220px;
            &Line2 {
                width: 380px;
            }
            &Line3 {
                width: 500px;
            }
        }
        &Title {
            font-family: $secondaryFont;
            color: $mainColor;
            font-size: 20px;
        }
        &SubWrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
        }
        &Name {
            font-family: $secondaryFont;
            color: $thirdColor;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            &SmallFs {
                font-size: 12px;
            }
        }
        &Value {
            font-family: $secondaryFont;
            color: $thirdColor;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
}