@import './../../style/variables';

.pve {
    &Countdown {
        margin: 0 0 6px 0;
        width: 130px;
        color: $secondaryColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 25px;
        margin-top: 15px;
    }
    &CardContentInner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &ProgressOuter {
        width: 100%;
        height: 8px;
        border-radius: 3px;
    }
    &Progress {
        height: 100%;
        border-radius: 3px;
    }
}