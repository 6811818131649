@import './../../style/variables';

.pveBet {
    &Wrapper {
        margin-top: -180px;
        margin-bottom: 100px;
    }
    &Btn {
        &Wrapper {
            width: 400px;
            display: flex;
            justify-content: space-around;
            margin: 0 auto;
        }
        width: 150px;
        height: 50px;
        font-size: 30px !important;
        font-family: $primaryFont !important;
        &New {
            width: 180px;
        }
    }
    &Title {
        color: $mainColor;
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 35px;
        margin-top: 20px;
        letter-spacing: 1px;
        span {
            color: $secondaryColor;
        }
    }
    &Control {
        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 500px;
            margin: 0 auto;
            margin-top: 20px;
            position: relative;
        }
        &Btn {
            width: 50px;
            height: 40px;
        }
        &X2 {
            margin-left: 30px !important;
        }
    }
    &Input {
        width: 100px !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        label {
            color: $secondaryColor;
        }
        &Disabled {
            width: 100px !important;
            margin-left: 20px !important;
            margin-right: 20px !important;
            label {
                color: $inputDisabled !important;
            }
        }
    }
    &Limits {
        position: absolute;
        top: 59px;
        left: 153px;
        color: $secondaryColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 15px;
        &Disable {
            color: $inputDisabled;
        }
    }
    &Reward {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 25px;
        &Title {
            color: $mainColor;
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 30px;
            margin-top: 20px;
            letter-spacing: 1px;
        }
        &Victory {
            color: $mainColor;
        }
    }
}

.pveBetInputDisabled {
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: $inputBorderDisabled !important;
    }
}

.css-1x5jdmq.Mui-disabled {
    -webkit-text-fill-color: unset !important;
}
#bet {
    color: $thirdColor !important;
}
#disableBet {
    color: $inputDisabled !important;
}