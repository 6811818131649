@import './../../style/variables';

.pveDocs {
    &Wrapper {
        margin-bottom: 100px;
    }
    &LinksWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 50px;
    }
    &Link {
        width: 200px;
        margin-bottom: 50px;
        text-decoration: none;
        &Title {
            color: $mainColor;
            font-family: $primaryFont;
            font-size: 30px;
            letter-spacing: 1px;
        }
    }
    &Img {
        width: 128px;
        height: 128px;
    }
    &Break {
        width: 100%;
    }
}