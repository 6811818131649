@import './variables';

.instant-show {
    display: block !important;
}
  
.instant-hide {
    display: none !important;
}

.show {
    display: block !important;
    transition: all .3s ease-in;
}

.showFlex {
    display: flex !important;
    transition: all .3s ease-in;
}

.hide {
    display: none !important;
    transition: all .3s ease-in;
}

.textGreen {
    color: $mainColor !important;
}

.textRed {
    color: $secondaryColor !important;
}

.textAndBorderGreen {
    color: $mainColor !important;
    border: 2px solid $mainColor !important;
}

.textAndBorderRed {
    color: $secondaryColor !important;
    border: 2px solid $secondaryColor !important;
}

.arrowBorderRed span {
    border-bottom: 2px solid $secondaryColor !important;
    border-right: 2px solid $secondaryColor !important;
}

.arrowBorderGreen span {
    border-bottom: 2px solid $mainColor !important;
    border-right: 2px solid $mainColor !important;
}

.colorCommon {
    color: $rarityColorCommon !important;
}
.colorUncommon {
    color: $rarityColorUncommon !important;
}
.colorRare {
    color: $rarityColorRare !important;
}
.colorEpic {
    color: $rarityColorEpic !important;
}
.colorLegendary {
    color: $rarityColorLegendary !important;
}

.grayscaleOn {
    filter: grayscale(100%);
}

.grayscaleOff {
    filter: grayscale(0%);
}

.pveGrayscaleOff {
    filter: grayscale(0);
    transform: scale(1.1);
    transition: 0.5s;
}
.pveGrayscaleOn {
    filter: grayscale(1);
    transform: scale(1);
    transition: 0.5s;
}

.playGameBtn {
    margin-top: 150px !important;
    width: 250px;
    height: 65px;
    font-size: 50px !important;
    font-family: $primaryFont !important;
    letter-spacing: 1px !important;
}

.pveSkeleton {
    background-color: $skeletonBg !important;
    display: inline-block !important;
}

.smoothBackground {
    background-color: rgba(0, 0, 0, .3) !important;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    transition: box-shadow .3s cubic-bezier(.4,0,.2,1) 0ms;
    // border: 12px solid var(--mainTextColor);
    border-radius: 0;
}

.notFoundWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
}
.notFoundText {
    font-size: 60px;
    font-family: $primaryFont;
    color: $secondaryColor;
}
.notFoundLink {
    font-size: 30px;
    font-family: $primaryFont;
    color: $linkColor;
    text-decoration: none;
    &:hover {
        color: $linkColorHover;
    }
}

// animate for ghost cat
@keyframes pulsate {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}