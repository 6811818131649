@import './../../style/variables';

.plug {
    &Img {
        width: 300px;
        height: 300px;
        margin-top: 30px;
    }
    &Text {
        font-family: $primaryFont;
        font-size: 40px;
        color: $mainColor;
        margin-top: 20px;
        span {
            color: $secondaryColor;
        }
    }
}