@import '../../../style/variables';

.pveRules {
    &Title {
        margin-top: 50px;
        color: $mainColor;
        font-family: $primaryFont;
        font-size: 60px;
    }
    &Wrapper {
        margin-top: 30px;
        margin-bottom: 100px;
        min-height: 550px;
    }
    &Item {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 18px;
        margin-right: 10px;
        text-align: left;
        margin-bottom: 20px;
    }
}