@import './../../style/variables';

.pve {
    &Progress {
        &Wrapper {
            position: relative;
            height: 120px;
            width: 600px;
            margin: 0 auto;
            margin-top: 30px;
        }
        &SmallIcon {
            position: absolute;
            width: 100px;
            height: 100px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
        }
        &BackgroundLine {
            position: absolute;
            top: 29px;
            left: 339px;
            width: 250px;
            height: 30px;
            background: $progressBarBg;
            border: 5px solid $progressBarBorder;
            border-radius: 0 25px 25px 0;
            z-index: 9;
            &User {
                transform: rotate(180deg);
                left: 0px;
            }
        }
        &OuterLine {
            height: 30px;
            border-radius: 0 25px 25px 0;
            width: 0;
            background: $secondaryColor;
        }
        &Value {
            position: absolute;
            color: $mainColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 20px;
            top: 70px;
            &User {
                left: 181px;
            }
            &Enemy {
                left: 360px;
            }
        }
        &DisciplineName {
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 25px;
        }
    }

    // race info
    &Race {
        &Cat {
            height: 655px;
        }
        &Wrapper {
            display: flex;
            justify-content: space-between;
        }
        &CatImg {
            width: 300px;
            height: 300px;
            &Enemy {
                transform: scale(-1, 1);
            }
            &Wrapper {
                width: 300px;
                height: 300px;
            }
        }
        &TitleValue {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 25px;
            margin-top: 5px;
            span {
                font-weight: 400;
                color: $secondaryColor;
            }
        }
        &Rarity {
            display: flex;
            justify-content: center;
            span {
                margin-left: 8px;
            }
        }
        &RarityIcon {
            padding-top: 2px;
            margin-left: 8px;
        }
        &IconLightningImg {
            width: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &Value {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            &Wrapper {
                width: 75px;
            }
            &Strength {
                display: inline-block;
                background: $strengthColor;
                position: relative;
                width: 56px;
                height: 56px;
                border-radius: 16px;
                margin-right: 16px;
                box-shadow: rgba(129, 194, 89, 0.3) 0px 4px 8px;
            }
            &Stamina {
                display: inline-block;
                background: $staminaColor;
                position: relative;
                width: 56px;
                height: 56px;
                border-radius: 16px;
                margin-right: 16px;
                box-shadow: rgba(241, 86, 76, 0.3) 0px 4px 8px;
            }
            &Speed {
                display: inline-block;
                background: $speedColor;
                position: relative;
                width: 56px;
                height: 56px;
                border-radius: 16px;
                margin-right: 16px;
                box-shadow: rgba(130, 109, 212, 0.3) 0px 4px 8px;
            }
            &Icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &Name {
                text-align: left;
                color: $thirdColor;
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 18px;
            }
            &NumStrength {
                color: $strengthColor;
                font-family: $secondaryFont;
                font-weight: 600;
                font-size: 23px;
            }
            &NumStamina {
                color: $staminaColor;
                font-family: $secondaryFont;
                font-weight: 600;
                font-size: 23px;
            }
            &NumSpeed {
                color: $speedColor;
                font-family: $secondaryFont;
                font-weight: 600;
                font-size: 23px;
            }
        }
        &Ratio {
            margin-top: 10px;
            &Color {
                color: $mainColor;
            }
        }
        &DisciplineTitle {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 30px; 
            margin-top: 30px;
        }
        &DisciplineIcon {
            width: 70px;
            display: block;
            margin: 0 auto;
            margin-top: 5px;
            &Wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 250px;
                margin: 0 auto;
                margin-top: 10px;
            }
        }
        &WinImg {
            width: 385px;
            margin-top: -55px;
        }
        &LoseImg {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 400px;
            border-radius: 25px;
            &Head {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 100px;
                width: 65px;
            }
        }
        &WinText {
            color: $winColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 32px;
            margin-top: 20px;
        }
        &WinReward {
            font-size: 35px;
        }
        &LoseText {
            margin-top: 30px;
            position: relative;
            width: 400px;
            height: 300px;
        }
        &LoseContentText1 {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 32px;
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translateX(-50%);
        }
        &LoseContentText2 {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 32px;
            position: absolute;
            top: 222px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
        }
        &BalanceErrorText {
            color: $secondaryColor; 
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 30px;
            margin-top: 100px;
            width: 500px;
            &Cat {
                margin-top: 30px;
            }
        }
        &BalanceText {
            margin-top: 50px;
        }
        &BalanceValue {
            margin-top: 0 !important;
        }
        &BalanceTokenSymbol {
            text-decoration: none !important;
            color: $secondaryColor; 
            font-family: $primaryFont;
            font-weight: 400;
        }
        // ghost
        &Ghost {
            &Cat {
                width: 300px;
                position: relative;
                opacity: 0.7;
                // animation: pulsate 0.5s ease-in-out infinite both;
            }
            &Arm {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            &User {
                svg {
                    transform: scale(-1, 1);
                }
            }
            &Error {
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
                color: $secondaryColor;
                font-family: "Krub";
                font-weight: 600;
                font-size: 20px;
            }
        }
    }
}