@import './../../style/variables';

.pveWarningMessage {
    &Wrapper {
        background-color: $bgColor !important;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: 12px solid $mainColor;
        border-radius: 0;
        width: 100%;
        margin-top: 100px;
        height: 80px;
        border-image-source: url('../../assets/frames/warning-slime-frame.png') !important;
        border-image-slice: 135 !important;
        border-image-repeat: stretch !important;
        border-image-width: auto !important;
        border-image-outset: 14px 16px 38px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &Text {
        font-family: $secondaryFont;
        color: $warningTextColor;
        font-size: 20px;
        padding: 14px 76px 14px 15px;
    }
}