@font-face {
    font-family: 'Shlop';
    src: local('../fonts/shlop'), local('shlop'),
        url('../fonts/shlop.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Oswald';
    src: local('./fonts/Oswald SemiBold'), local('Oswald-SemiBold'),
        url('../fonts/Oswald-SemiBold.woff2') format('woff2'),
        url('../fonts/Oswald-SemiBold.woff') format('woff'),
        url('../fonts/Oswald-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Krub';
    src: local('./fonts/Krub Medium'), local('Krub-Medium'),
        url('../fonts/Krub-Medium.woff2') format('woff2'),
        url('../fonts/Krub-Medium.woff') format('woff'),
        url('../fonts/Krub-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Oswald';
    src: local('./fonts/Oswald Bold'), local('Oswald-Bold'),
        url('../fonts/Oswald-Bold.woff2') format('woff2'),
        url('../fonts/Oswald-Bold.woff') format('woff'),
        url('../fonts/Oswald-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Krub';
    src: local('./fonts/Krub SemiBold'), local('Krub-SemiBold'),
        url('../fonts/Krub-SemiBold.woff2') format('woff2'),
        url('../fonts/Krub-SemiBold.woff') format('woff'),
        url('../fonts/Krub-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Oswald';
    src: local('./fonts/Oswald Medium'), local('Oswald-Medium'),
        url('../fonts/Oswald-Medium.woff2') format('woff2'),
        url('../fonts/Oswald-Medium.woff') format('woff'),
        url('../fonts/Oswald-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Krub';
    src: local('./fonts/Krub Bold'), local('Krub-Bold'),
        url('../fonts/Krub-Bold.woff2') format('woff2'),
        url('../fonts/Krub-Bold.woff') format('woff'),
        url('../fonts/Krub-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Krub';
    src: local('./fonts/Krub Regular'), local('Krub-Regular'),
        url('../fonts/Krub-Regular.woff2') format('woff2'),
        url('../fonts/Krub-Regular.woff') format('woff'),
        url('../fonts/Krub-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}