@import '../../../style/variables';

.pveInstruction {
    &Subtitle {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: $secondaryFont;
        color: $mainColor;
        &Main {
            color: $secondaryColor;
            font-family: $secondaryFont;
            font-size: 30px;
            margin-top: 20px;
        }
    }
    &Item {
        text-align: justify !important;
        span {
            font-weight: 600;
            font-size: 20px;
            color: $mainColor;
        }
    }
    &Img {
        display: block;
        margin: 0 auto;
        margin-top: 25px;
    }
    &Warning {
        color: $secondaryColor !important;
    }
    &Link {
        color: $linkColor;
        &:hover {
            color: $linkColorHover;
        }
    }
}

@media ( max-width: 850px ) {
    .pveInstruction {
        &Img {
            width: 500px;
        }  
    }
}

@media ( max-width: 550px ) {
    .pveInstruction {
        &Img {
            width: 300px;
        }  
    }
}

@media ( max-width: 370px ) {
    .pveInstruction {
        &Img {
            width: 280px;
        }  
    }
}