@import './../../style/variables';

.pveBalance {
    &Title {
        color: $mainColor;
        font-family: $primaryFont;
        font-size: 65px;
        margin-top: 30px;
    }
    &Value {
        color: $winColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 35px;
        margin-top: 10px;
        span {
            text-decoration: underline;
        }
    }
    &TokenSymbol {
        text-decoration: none !important;
        color: $secondaryColor; 
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 37px;
    }
    &BloodyLine {
        margin-top: 30px;
    }
    // &Line {
    //     border: 1px solid #7c7b7b;
    //     height: 2px;
    //     width: 40%;
    //     margin: 0 auto;
    //     margin-top: 10px;
    // }
    &DepositTitle {
        color: $mainColor;
        font-family: $primaryFont;
        font-size: 60px;
        margin-top: 30px;
    }
    &Address {
        color: $mainColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
    }
    &WrongNetwork {
        color: $secondaryColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 25px;
        &Wrapper {
            margin-top: 20px;
            margin-bottom: 25px;
        }
    }
    &WalletTokens {
        &Wrapper {
            margin-top: 10px;
        }
        &Title {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 5px;
        }
        &Wlkn {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 400;
            font-size: 18px;
        }
    }
    &DepositWrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 320px;
        margin: 0 auto;
        margin-top: 10px;
    }
    &SupportedWallets {
        display: flex;
        justify-content: space-between;
        width: 435px;
        margin: 0 auto;
        align-items: center;
        color: $mainColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 20px;
        margin-top: 15px;
        margin-bottom: 40px;
        &Item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        &Img {
            width: 25px;
            height: 25px;
            margin-right: 7px;
        }
    }
    &Btn {
        &Connect {
            font-family: $primaryFont !important;
            font-size: 20px !important;
            width: 150px;
            letter-spacing: 1px !important;
            margin-top: 25px !important;
        }
        &Disconnect {
            width: 120px;
        }
        &Deposit {
            font-family: $primaryFont !important;
            font-size: 20px !important;
            letter-spacing: 1px !important;
        }
        &SwitchNetwork {
            margin-top: 10px !important;
        }
    }
    &AmountDeposit {
        width: 150px;
        p {
            color: $mainColor !important;
        }
        &Disable {
            width: 150px;
            p {
                color: $inputDisabled !important;
            }
        }
    }
    &DepositInput {
        color: $mainColor !important;
        &Disable {
            color: $inputDisabled !important;
            .css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
                -webkit-text-fill-color: $inputDisabled;
            }
            &:after {
                border-bottom: 2px solid $inputDisabled !important;
            }
            &:before {
                border-bottom: 2px solid $inputDisabled !important;
            }
        }
        &:after {
            border-bottom: 1px solid $mainColor !important;
        }
        &:before {
            border-bottom: 1px solid $mainColor !important;
        }
    }
    &ErrorMessage {
        color: $secondaryColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 18px;
        width: 450px;
        margin: 0 auto;
        margin-top: 10px;
    }
}

.wallet-adapter-button {
    margin-top: 15px;
    height: 40px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: #fbbc54;
    color: #000;
    &:hover {
        background-color: #b18239 !important;
    }
}
.wallet-adapter-modal {
    overflow-y: scroll;
    .wallet-adapter-button {
        color: #fff;
        background-color: #10141f;
        &:hover {
            background-color: #1a1f2e !important;
        }
    }
}
