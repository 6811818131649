@import './../../style/variables';

.pveRef {
    &Wrapper {
        margin-bottom: 100px;
    }
    &Balance {
        &Wrapper {
            display: flex;
            justify-content: space-around;
            margin-top: 15px;
        }
        &Title {
            font-size: 35px;
        }
        &Item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 305px;
        }
        &ArrowIcon {
            width: 65px !important;
            height: 65px !important;
        }
    }
    &Btn {
        font-family: $primaryFont !important;
        font-size: 20px !important;
        margin-top: 40px !important;
        letter-spacing: 1px !important;
    }
    &UpdateInfo {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 17px;
        margin-top: 60px;
    }
    &Codes {
        &Wrapper {
            margin-top: 50px;
            display: flex;
            justify-content: space-around;
        }
        &Item {
            &Wrapper {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 10px;
            }
        }
        &Title {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 400;
            font-size: 20px;
            display: flex;
            align-items: center;
        }
        &Value {
            color: $secondaryColor;
            font-family: $secondaryFont;
            font-weight: 900;
            font-size: 30px;
            margin: 0 10px 0 10px;
            &Invited {
                color: $thirdColor;
                font-weight: 400;
            }
        }
        &PercentValue {
            color: $secondaryColor;
            font-family: $secondaryFont;
            font-weight: 900;
            font-size: 25px;
            margin: 0 10px 0 10px;
        }
        &LinkMore {
            display: flex;
            align-items: center;
        }
    }
    &NumberRef {
        &Title {
            margin-top: 60px;
            font-size: 35px;
        }
        &Value {
            color: $secondaryColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 35px;
        }
    }
    &Stats {
        &Title {
            margin-top: 40px;
            &Symbol {
                font-size: 45px;
            }
        }
        &Wrapper {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
        }
        &PeriodName {
            color: $thirdColor;
            font-family: $secondaryFont;
            font-weight: 400;
            font-size: 20px;
        }
        &Value {
            color: $winColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 22px;
            margin-top: 5px;
        }
        &Item {
            width: 220px;
        }
        &ItemAllTime {
            margin: 0 auto;
            margin-top: 20px;
        }
    }
    &Calculate {
        color: $secondaryColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 22px;
        margin-top: 20px;
    }
}

.list {
    &CopyWrapper {
        display: flex;
        position: relative;
        align-items: center;
    }
    &CopyIcon {
        height: 23px !important;
        width: 23px !important;
        margin-left: 5px;
        filter: invert(59%) sepia(13%) saturate(153%) hue-rotate(104deg) brightness(94%) contrast(88%);
        cursor: pointer;
        &:hover {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(4deg) brightness(101%) contrast(103%);
        }
    }
    &CopyCheckIcon {
        height: 23px !important;
        width: 23px !important;
        margin-left: 5px;
        cursor: pointer;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(4deg) brightness(101%) contrast(103%);
    }
    &HintCopyWrapper {
        position: absolute;
        width: 80px;
        height: 25px;
        background-color: $mainColor;
        border-radius: 8px;
    }
    &HintCopyText {
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 12px;
        color: $bgHeader;
        position: absolute;
        z-index: 11;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }
    &HintCopyTriangle {
        border: 8px solid transparent; 
        border-top: 8px solid $mainColor;
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
    }
    &HintCopyPosition {
        top: -30px;
        left: -24px;
    }
}