@import '../../style/variables';

.footer {
    &Wrapper {
        flex: 0 0 auto;
        height: 60px;
        background-color: $bgHeader;
        position: relative;
    }
    &Content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 19px;
    }
    &Date {
        font-family: $primaryFont;
        font-size: 22px;
        color: $mainColor;
    }
    &MyLink {
        margin-left: 5px;
        color: $mainColor;
    }
    &Link {
        font-family: $primaryFont;
        font-size: 22px;
        color: $mainColor;
        &Wrapper {
            display: flex;
            justify-content: space-between;
            width: 200px;
        }
    }
    &SlimeImg-1 {
        width: 171px;
        height: 130px;
        position: absolute;
        top: -100px;
        left: 26px;
    }
    &SlimeImg-2 {
        width: 176px;
        height: 96px;
        position: absolute;
        top: -60px;
        right: 91px;
    }
}