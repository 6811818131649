@import './../../style/variables';

.pveFaucet {
    &Wrapper {
        margin-bottom: 100px;
    }
    &Title {
        color: $secondaryColor;
    }
    &AdBlocker {
        color: $secondaryColor;
    }
    &Text {
        color: $thirdColor;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 23px;
        margin-top: 40px;
    }
    &AdH {
        display: inline-block;
        width: 728px;
        height: 90px;
    }
    &AdV {
        display: inline-block;
        width: 160px;
        height: 600px;
    }
    &HorizontalAd {
        width: 728px;
        height: 90px;
        // border: dashed 2px #ef5350;
        margin: 0 auto;
        margin-top: 40px;
        overflow: hidden;
    }
    &MainBlockWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
    }
    &VerticalAd {
        width: 160px;
        height: 600px;
        overflow: hidden;
        // border: dashed 2px #ef5350;
    }
    &AdsTestModeH {
        width: 724px;
        height: 86px;
        border: 2px dashed $secondaryColor;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $secondaryFont;
        color: $secondaryColor;
        font-size: 20px;
        font-weight: 600;
    }
    &AdsTestModeV {
        width: 156px;
        height: 596px;
        border: 2px dashed $secondaryColor;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $secondaryFont;
        color: $secondaryColor;
        font-size: 20px;
        font-weight: 600;
    }

    &MainTableWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
    }
    &Balance {
        &Title {
            color: $mainColor;
            font-family: $primaryFont;
            font-size: 45px;
            letter-spacing: 1px;
        }
        &Value {
            color: $winColor;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 25px;
            margin-top: 10px;
            span {
                text-decoration: underline;
            }
        }
    }
    &Table {
        &Title {
            text-shadow: 0 0 2px #000000;
            background: linear-gradient(to bottom,$faucetGradientFirstColor 5%,$faucetGradientSecondColor 100%);
            border: 1px solid $thirdColor;
            color: $thirdColor;
            width: 210px;
            height: 37px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
            font-size: 20px;
            font-weight: 700;
        }
        &Row {
            width: 210px;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fdfbec;
            border: 1px solid #ccc;
        }
    }
    &DigitsWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
    }
    &Digit {
        height: 75px;
        width: 50px;
        color: $mainColor;
        background: linear-gradient(to bottom,$faucetCounterGradientFirstColor 0%,$faucetCounterGradientSecondColor 100%);
        margin-right: 5px;
        text-shadow: 2px 2px $faucetCounterShadowColor;
        font-size: 55px;
        border-radius: 5px; 
        padding-bottom: 9px;
    }
    &WinText {
        color: $winColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 29px;
        margin-top: 15px;
        span {
            color: $secondaryColor; 
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 29px;
        }
    }
    &AdBlockerEnable {
        color: $secondaryColor;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 29px;
        margin-top: 15px
    }
    &Btn {
        font-weight: 600 !important;
        width: 110px;
        height: 45px;
        font-size: 20px !important;
        letter-spacing: 4px !important;
        margin-top: 15px !important;
    }
    &Error {
        color: $secondaryColor;
    }
}